import axios from 'axios';
import { headers } from '../../defaults';
import { API_URL } from '../../../environment';
import { UNAUTH_USER } from '../../types';

export const fetchJwtToken = () => async dispatch => {
  try {
    const response = await axios.post(
      `${API_URL}/admin/secure_messagings/generate_jwt`,
      {},
      headers(),
    );
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      dispatch({ type: UNAUTH_USER });
    } else {
      return error;
    }
  }
};
