import axios from 'axios';
import { UPDATE_USER_SUCCESS } from '../types';
import { headers } from '../defaults';
import { API_URL } from '../../environment';

export function updateUser(values, callback = () => {}) {
  // eslint-disable-next-line func-names
  return function(dispatch) {
    axios
      .put(`${API_URL}/clinic_admin/users/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response,
        });
      });
  };
}
